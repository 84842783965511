div.status__container {
	display: flex;
	margin: 15vh 0 15vh 0;
	flex-direction: column;
	float: left;
	width: 50%;
	align-content: center;
	justify-content: space-between;

	.clock__container {
		margin: 2vh 2vw 2vh 2vw;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		p {
			font-size: large;
		}
	}

	.status {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}
}
