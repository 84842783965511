@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;600;700&display=swap");

div.social__container {
	text-align: center;
	display: flex;
	margin: 15vh 0 10vh 0;
	flex-direction: column;
	float: right;
	width: 50%;
	align-content: center;
	justify-content: space-between;

	p {
		margin: 5vh 5vw 5vh 5vw;
		font-size: large;
	}

	div.button__container {
		margin: 2vh 2vw 2vh 2vw;
		color: rgb(57, 48, 182);
		border-radius: 20px;

		div.button__wrapper {
			z-index: 0;
			overflow: hidden;
			margin-bottom: 16px;
			border-radius: 20px;
			background-color: rgb(79, 124, 254);
			color: rgb(255, 255, 255);
			transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
				color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
				border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
				transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
				background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;

			// TODO: Make dynamic rulesets from JSON
			&#github {
				border: 2px solid #000;
				background-color: #000;
				&:hover {
					color: #000;
					background-color: #fff;
				}
			}

			&#crowdin {
				border: 2px solid #2e3340;
				background-color: #2e3340;
				&:hover {
					color: #2e3340;
					background-color: #fff;
				}
			}

			&#premid {
				border: 2px solid #7289da;
				background-color: #7289da;
				&:hover {
					color: #7289da;
					background-color: #fff;
				}
			}

			&#discord {
				border: 2px solid #5865f2;
				background-color: #5865f2;
				&:hover {
					color: #5865f2;
					background-color: #fff;
				}
			}

			&#twitter {
				background-color: #00acee;
				border: 2px solid #00acee;
				&:hover {
					color: #00acee;
					background-color: #fff;
				}
			}

			*,
			::before,
			::after {
				box-sizing: inherit;
			}

			a {
				margin: 0px;
				border: none;
				text-align: center;
				cursor: pointer;
				text-decoration: none;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;
				height: auto;
				position: relative;
				padding: 16px 20px;
				appearance: none;
				display: block;
				width: 100%;
				overflow-wrap: break-word;
				word-break: break-word;
				-ms-hyphens: auto;
				-webkit-hyphens: auto;
				hyphens: auto;
				white-space: normal;
				background: none;
				color: inherit;
				font-family: Karla, sans-serif;
				font-size: 16px;
				font-weight: 700;
				box-sizing: border-box;
				-webkit-font-smoothing: antialiased;
			}
		}
	}
}
