@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap);

.titlebar {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 28px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(27, 34, 45, 0.8);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
	z-index: 1;
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
	font-family: "JetBrains Mono", monospace;

	.spaces {
		display: flex;
		align-items: center;
		padding: 0 5px;

		.space {
			display: flex;
			align-items: center;
			margin-right: 5px;
			padding: 3px 6px;
			font-size: 11px;
			background-color: rgba(57, 70, 94, 0.81);
			border-radius: 3px;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
			cursor: pointer;

			&.space--active {
				color: #1e2737;
				background-color: #fff;
			}

			svg {
				flex: 0 0 11px;
				width: 11px;
				height: 11px;
				margin-right: 6px;
				fill: currentColor;
				-webkit-transform: translateZ(0);
				transform: translateZ(0);
				margin-left: 6px;
				opacity: 0.5;
			}
		}
	}

	.code-btn {
		display: flex;
		align-items: center;
		margin-right: 5px;
		padding: 3px 6px;
		font-size: 11px;
		color: #1e2737;
		background-color: #fff;
		border-radius: 3px;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
		cursor: pointer;

		img {
			flex: 0 0 11px;
			width: 11px;
			height: 11px;
			margin-right: 6px;
			fill: currentColor;
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
		}
	}

	.process {
		position: absolute;
		top: 6px;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 11px;
		text-align: center;
		pointer-events: none;
		touch-action: none;

		@media screen and (max-width: 375px) {
			& {
				display: none;
			}
		}
	}
}
