@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@keyframes fadein {
	0% {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}

body,
html {
	background: #000;
	color: #fff;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	::-webkit-scrollbar {
		display: none;
	}

	#root {
		clear: both;
		width: 100%;

		div.background {
			animation: fadein 0.5s ease-in;
			z-index: -1;
			position: fixed;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			filter: blur(4px);
			-webkit-filter: blur(4px);
			background: rgba(0, 0, 0, 0.4);
			background-blend-mode: darken;
			background-image: url(../../../public/img/bg.jpg);
			background-position: center top;
			background-repeat: no-repeat;
			background-size: cover;
			margin: 0;
			transition: background-image 0.8s ease-in;

			&[id="1"] {
				background-image: url(../../../public/img/bg.jpg);
			}

			&[id="2"] {
				background-image: url(../../../public/img/music_bg.jpg);
			}

			&[id="3"] {
				background-image: url(../../../public/img/about_bg.jpg);
			}
		}

		div.container {
			clear: both;
			position: relative;
			z-index: 2;

			@media screen and (max-width: 600px) {
				& {
					flex-direction: row;
				}
				[class$="__container"] {
					width: 100%;
					margin: 2vh 0 4vh 0;
				}
			}
		}
	}
}
